var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-bidding"},[_c('div',{staticClass:"bidding-text"},[_vm._v("同步竞拍")]),_c('div',{staticClass:"bidding-main"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index},[_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"thumb-container"},[_c('div',{staticClass:"thumb"},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/auctiondetails',
                query: { auctiondetailsId: item.id }
              }}},[_c('img',{attrs:{"src":item.image+_vm.imgSize(1353,951),"alt":""}})])],1)])]),_c('div',{staticClass:"bidding-main-right"},[_c('div',{staticClass:"bidding-main-right-left"},[_c('div',{staticClass:"bidding-collot"},[_c('router-link',{attrs:{"target":"_blank","to":{
                path: '/auctiondetails',
                query: { auctiondetailsId: item.id }
              }}},[_vm._v(" "+_vm._s(item.chineseTitleAuction)+" ")]),(_vm.hide)?_c('a',{staticClass:"right-collot",on:{"click":function($event){return _vm.biddingCollot(item.id)}}},[_c('i',{staticClass:"iconfont ",class:[item.status === 1 ? 'icon-aixin' : 'icon-kongaixin'],staticStyle:{"color":"rgb(234, 49, 74)","font-size":"13px"}}),_c('a',{staticClass:"click-collot",attrs:{"href":"javascript:;"}},[_vm._v("收藏")])]):_vm._e(),(
                (item.auctionStatus === 1 && _vm.hide) ||
                  (item.auctionStatus === 3 && _vm.hide)
              )?_c('a',{staticClass:"right-collot",on:{"click":function($event){return _vm.startBidding(item.id)}}},[_c('i',{staticClass:"iconfont ",class:[
                  item.remindStatus === true
                    ? 'icon-renwutixing'
                    : 'icon-duanxintixing'
                ],staticStyle:{"color":"rgb(234, 49, 74)","font-size":"15px"}}),_c('a',{staticClass:"click-collot",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(item.remindStatus === true ? '取消提醒' : '开拍提醒'))])]):_vm._e()],1),_c('router-link',{staticStyle:{"color":"#2c3e50"},attrs:{"target":"_blank","to":{
              path: '/auctionhousedetails',
              query: { auctionhousedetailsid: item.auctionHouseId }
            }}},[_vm._v(" "+_vm._s(item.auctionHouseTitle)+" ")]),(item.deferment === false)?_c('p',[_c('span',[_vm._v(_vm._s(item.startTime.replace(/\//g, '-')))]),_c('span',[_vm._v("|")]),_c('span',[_vm._v("北京时间")])]):_c('a',{staticStyle:{"color":"#333","font-size":"0.104167rem","display":"block","padding-bottom":"0.104167rem","text-decoration":"underline"},attrs:{"href":"javascript:;"}},[_vm._v("延期待定")]),_c('p',{staticClass:"yeelow"},[_c('span',[_vm._v("保证金 "+_vm._s(item.earnestMoney))]),_c('span',{staticStyle:{"padding":"0 10px"}},[_vm._v("|")]),(item.auctionWay === 7)?_c('span',{staticStyle:{"color":"#CD5656"}},[_vm._v("直播专场")]):(item.auctionWay === 8)?_c('span',{staticStyle:{"color":"#cda156"}},[_vm._v("代拍专场")]):_vm._e()]),_c('p',[_c('img',{attrs:{"src":require("../assets/images/12.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(item.location))])])],1),_c('div',{staticClass:"right-browse"},[(item.auctionStatus === 1)?_c('router-link',{staticClass:"bidding-main-right-right",attrs:{"target":"_blank","to":{
              path: '/auctiondetails',
              query: { auctiondetailsId: item.id }
            }}},[_vm._v(" 预约出价中 ")]):_vm._e(),(item.auctionStatus === 2 && _vm.accessTokenShow === false)?_c('router-link',{staticClass:"bidding-main-right-right",staticStyle:{"background":"#D10000","color":"#fff","font-weight":"600"},attrs:{"target":"_blank","to":{
              path: '/buyerlive',
              query: {
                auctionId: item.id,
                auctionHouseTitle: item.auctionHouseTitle,
                title: item.chineseTitleAuction
              }
            }}},[_vm._v(" 直播中 ")]):_vm._e(),(
              item.auctionStatus === 2 &&
                _vm.accessTokenShow &&
                _vm.auctionHouseLoginNO
            )?_c('router-link',{staticClass:"bidding-main-right-right",staticStyle:{"background":"#D10000","color":"#fff","font-weight":"600"},attrs:{"target":"_blank","to":{
              path: '/buyerlive',
              query: {
                auctionId: item.id,
                auctionHouseTitle: item.auctionHouseTitle,
                title: item.chineseTitleAuction
              }
            }}},[_vm._v(" 直播中 ")]):_vm._e(),(
              item.auctionStatus === 2 &&
                _vm.accessTokenShow &&
                _vm.auctionHouseLoginN &&
                item.whetherSelfAuctionHouse === true
            )?_c('router-link',{staticClass:"bidding-main-right-right",staticStyle:{"background":"#D10000","color":"#fff","font-weight":"600"},attrs:{"target":"_blank","to":{
              path: '/liveauctionhouse',
              query: { liveId: item.id, title: item.chineseTitleAuction }
            }}},[_vm._v(" 直播中 ")]):_vm._e(),(
              item.auctionStatus === 2 &&
                _vm.accessTokenShow &&
                _vm.auctionHouseLoginN &&
                item.whetherSelfAuctionHouse === false
            )?_c('a',{staticClass:"bidding-main-right-right",staticStyle:{"background":"#D10000","color":"#fff","font-weight":"600"},attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.inoperable()}}},[_vm._v(" 直播中 ")]):_vm._e(),(item.auctionStatus === 3)?_c('router-link',{staticClass:"bidding-main-right-right",attrs:{"target":"_blank","to":{
              path: '/auctiondetails',
              query: { auctiondetailsId: item.id }
            }}},[_vm._v(" 拍卖顺延 ")]):_vm._e(),(item.auctionStatus === 4)?_c('router-link',{staticClass:"bidding-main-right-right",staticStyle:{"background":"#DEDEDE"},attrs:{"target":"_blank","to":{
              path: '/auctiondetails',
              query: { auctiondetailsId: item.id }
            }}},[_vm._v(" 已结束 ")]):_vm._e(),(
              item.overD !== undefined &&
                item.auctionStatus === 1 &&
                item.auctionWay === 8 &&
                item.deferment === false
            )?_c('span',{staticClass:"cut-off"},[_vm._v(_vm._s(item.endTime.replace(/\//g, '-'))+"委托截止")]):_vm._e(),(
              item.overD !== undefined &&
                item.auctionStatus === 1 &&
                item.auctionWay === 8 &&
                item.deferment === false
            )?_c('span',{staticClass:"cut-off pd-off"},[_c('i',{staticStyle:{"color":"rgb(197, 80, 86)","font-style":"normal"}},[_vm._v(_vm._s(item.overD))]),_vm._v("天"),_c('i',{staticStyle:{"color":"rgb(197, 80, 86)","font-style":"normal"}},[_vm._v(_vm._s(item.overH))]),_vm._v("时"),_c('i',{staticStyle:{"color":"rgb(197, 80, 86)","font-style":"normal"}},[_vm._v(_vm._s(item.overM))]),_vm._v("分"),_c('i',{staticStyle:{"color":"rgb(197, 80, 86)","font-style":"normal"}},[_vm._v(_vm._s(item.overS))]),_vm._v("秒")]):_vm._e(),(
              item.overD1 !== undefined &&
                item.auctionWay === 7 &&
                item.overD1 !== -1 &&
                item.auctionStatus == 1 &&
                item.deferment === false
            )?_c('span',{staticClass:"cut-off pd-off"},[_c('i',{staticStyle:{"font-style":"normal"}},[_vm._v("直播倒计时:")]),_c('i',{staticStyle:{"color":"rgb(197, 80, 86)","font-style":"normal"}},[_vm._v(_vm._s(item.overD1))]),_vm._v("天"),_c('i',{staticStyle:{"color":"rgb(197, 80, 86)","font-style":"normal"}},[_vm._v(_vm._s(item.overH1))]),_vm._v("时"),_c('i',{staticStyle:{"color":"rgb(197, 80, 86)","font-style":"normal"}},[_vm._v(_vm._s(item.overM1))]),_vm._v("分"),_c('i',{staticStyle:{"color":"rgb(197, 80, 86)","font-style":"normal"}},[_vm._v(_vm._s(item.overS1))]),_vm._v("秒")]):(
              item.overD === undefined &&
                item.auctionStatus === 1 &&
                item.deferment === false
            )?_c('span',{staticClass:"cut-off pd-off"},[_vm._v("委托已结束")]):_vm._e()],1)])])}),0),_c('div',{staticClass:"bidding-all"},[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/auctionhome' }}},[_vm._v(" 查看所有拍卖会 "),_c('a',{attrs:{"href":"javascript:"}},[_c('img',{attrs:{"src":require("../assets/images/13.png"),"alt":""}})])])],1),_c('Login',{ref:"login"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }