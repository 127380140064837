<template>
  <div class="container-liveBroadcasting" v-if="auctionListData.length>0">
    <div class="liveBroadcasting-top">
      <span>正在直播</span>
      <router-link v-if="count>4" class="allLive" target="_blank" :to="{
          path: '/AllLiveauction'
        }">
        <span class="black">查看全部</span>
        <span class="orange">{{count}}</span>
        <span class="black">场</span>
      </router-link>
      <div v-else>
        <span class="black">全部</span>
        <span class="orange">{{count}}</span>
        <span class="black">场</span>
      </div>
    </div>
    <div class="liveBroadcasting-con">
      <div class="liveBroadcasting-con-list" v-for="(item,index) in auctionListData" :key="index">
        <router-link target="_blank" :to="{
            path: '/auctiondetails',
            query: { auctiondetailsId: item.auctionId }
          }">
          <img :src="item.image+imgSize(1008)" class="img" />
        </router-link>
        <div>
          <div>
            <router-link class="auctionTitle" target="_blank" :to="{
                path: '/auctiondetails',
                query: { auctiondetailsId: item.auctionId  }
              }">
              {{item.auctionTitle}}
            </router-link>
          </div>
          <div>
            <router-link class="auctionHouseTitle" target="_blank" :to="{
                path: '/auctionhousedetails',
                query: { auctionhousedetailsid: item.auctionHouseId }
              }">
              {{ item.auctionHouseTitle }}
            </router-link>
          </div>
          <div>{{item.lot}}</div>
          <div>
            <span>第 {{item.inOrder}} 件/共 {{item.inAll}} 件</span>
            <router-link v-if="accessTokenShow === false" target="_blank" class="btn" :to="{
                path: '/buyerlive',
                query: {
                  auctionId: item.auctionId,
                  auctionHouseTitle: item.auctionHouseTitle,
                  title: item.auctionTitle
                }
              }">
              进 入 直 播
            </router-link>

            <router-link v-if="accessTokenShow &&auctionHouseLoginNO" target="_blank" class="btn" :to="{
                path: '/buyerlive',
                query: {
                  auctionId: item.auctionId,
                  auctionHouseTitle: item.auctionHouseTitle,
                  title: item.auctionTitle
                }
              }">
              进 入 直 播
            </router-link>

            <router-link v-if="accessTokenShow &&auctionHouseLoginN &&item.whetherSelfAuctionHouse === true"
              target="_blank" class="btn" :to="{
                path: '/liveauctionhouse',
                query: { liveId: item.auctionId, title: item.auctionHouseTitle }

              }">
              进 入 直 播
            </router-link>

            <a href="javascript:;" v-if="accessTokenShow &&auctionHouseLoginN &&item.whetherSelfAuctionHouse === false"
              class="btn" @click="inoperable()">
              进 入 直 播
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    liveAuctioningList
  } from '../api/index'
  export default {
    name: 'LiveBroadcasting',
    data() {
      return {
        auctionListData: [],
        count: 0,
        accessTokenShow: false,
        auctionHouseLoginN: false,
        auctionHouseLoginNO: false
      }
    },
    created() {
      if (localStorage.getItem('accessToken')) {
        this.accessTokenShow = true
      } else {
        this.accessTokenShow = false
      }
      if (localStorage.getItem('auctionHouseLoginNum')) {
        if (localStorage.getItem('auctionHouseLoginNum') === '1') {
          this.auctionHouseLoginN = true
        } else if (localStorage.getItem('auctionHouseLoginNum') === '2') {
          this.auctionHouseLoginNO = true
        }
      }
      this.auctionList()
    },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      inoperable() {
        this.$message.error('拍卖行账户不可以进行该操作，请切换至买家账户')
      },
      // 拍卖会数据初始化
      async auctionList() {
        const param = {
          page: 1,
          pageSize: 99,
          userid: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          x_user_id: Number(localStorage.getItem('accessId'))
        }
        const res = await liveAuctioningList(param)
        if (res.code === 0) {
          this.count = res.data.allCount || 0
          if (!res.data.list) return
          const accessId = localStorage.getItem('accessId')
          res.data.list.map(item => {
            item.whetherSelfAuctionHouse = item.auctionHouseRegisterId == accessId
          })
          this.auctionListData = res.data.list.slice(0, 4) || []
        } else {
          this.$message.error(res.msg)
        }
      }
    }

  }
</script>

<style lang="less" scoped>
  .container-liveBroadcasting {
    >.liveBroadcasting-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 90px;
      box-sizing: border-box;
      margin-left: 67px;
      padding-top: 30px;
      border-bottom: 1px solid #bfc3c4;
      width: 1785px;

      >span {
        font-weight: 500;
        font-size: 28px;

      }

      .black {
        color: #666666;
      }

      .orange {
        color: #CDA156;
      }

      >.allLive {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #666666;

        &:hover {
          text-decoration: underline;
        }

        >span {
          font-size: 20px;
          font-weight: 400;
        }

      }

      >div {
        display: flex;
        align-items: center;

        >span {
          font-size: 20px;
          font-weight: 400;
        }

      }
    }

    >.liveBroadcasting-con {
      width: 1785px;
      margin-left: 67px;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;

      >.liveBroadcasting-con-list {
        &:hover {
          box-shadow: 2px 2px 20px 2px rgba(46, 46, 46, 0.2);
        }

        display: flex;
        align-items: center;
        height: 340px;
        margin-top: 20px;
        background: #FFFFFF;
        border: 1px solid #BEC3C6;

        .img {
          width: 336px;
          height: 336px;
          object-fit: contain;

          &:hover {
            box-shadow: 2px 2px 10px 2px rgba(126, 126, 126, 0.2);
          }
        }

        >div {
          width: 520px;
          padding: 40px;

          >div {
            text-align: left;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            .auctionTitle {
              &:hover {
                text-decoration: underline;
              }

              font-size: 24px;
              font-weight: 500;
              color: #333;
            }

            .auctionHouseTitle {
              &:hover {
                text-decoration: underline;
              }

              color: #666666;
              font-size: 20px;
            }

            &:nth-child(2) {
              margin-top: 20px;
            }

            &:nth-child(3) {
              color: #666666;
              font-size: 20px;
              margin-top: 99px;
            }

            &:nth-child(4) {
              margin-top: 6px;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;

              >span {
                color: #CDA156;
                font-size: 20px;
                font-weight: 600;
              }

              >.btn {
                width: 180px;
                height: 44px;
                background: rgba(209, 0, 0, .7);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #FFFFFF;
                font-size: 18px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
</style>
